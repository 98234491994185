// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.css";
import "./App.css";
import { useUser } from "./UserContext";
import LoginScreen from "./LoginScreen";
import Dashboard from "./Dashboard";
import OAuthCallback from "./OAuthCallback";
import HistoryView from "./HistoryView";
import AccountView from "./AccountView";
import NavigationSidebar from "./NavigationSidebar";
import NavigationBottombar from "./NavigationBottombar";
import { GlobalSettingsProvider } from "./context/GlobalSettingsContext"; // Import the GlobalSettingsProvider

function App() {
  const { currentUser, authInitialized } = useUser();

  // Render a loading state or similar until authInitialized is true
  if (!authInitialized) {
    return <div className="loading-container d-flex">Loading...</div>;
  }

  return (
    <GlobalSettingsProvider>
      <ToastContainer />
      <Router>
        <div className="App d-flex">
          {currentUser && (
            <div className="sidebar-container">
              <NavigationSidebar />
            </div>
          )}
          {currentUser && (
            <div className="bottombar-container">
              <NavigationBottombar />
            </div>
          )}
          <div className="content w-100 d-flex">
            <Routes>
              <Route
                path="/"
                element={
                  currentUser ? (
                    <Navigate to="/dashboard" />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/login"
                element={
                  !currentUser ? <LoginScreen /> : <Navigate to="/dashboard" />
                }
              />
              <Route
                path="/dashboard"
                element={currentUser ? <Dashboard /> : <Navigate to="/login" />}
              />
              <Route path="/authorization" element={<OAuthCallback />} />
              <Route
                path="/history"
                element={
                  currentUser ? <HistoryView /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/account"
                element={
                  currentUser ? <AccountView /> : <Navigate to="/login" />
                }
              />
              {/* More routes can be added here */}
            </Routes>
          </div>
        </div>
      </Router>
    </GlobalSettingsProvider>
  );
}

export default App;
