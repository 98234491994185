import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase-config"; // Ensure this points to your Firebase config file
import { fetchUserSettings, saveUserSettings } from "../firebase-utils"; // Utility functions to interact with Firebase

// Create context
const GlobalSettingsContext = createContext();

// Context provider component
export const GlobalSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    userPreferences: {},
    calendarIntegrated: false,
    accessToken: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // Track mounted state
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && isMounted) {
        const userSettings = await fetchUserSettings(user.uid);
        if (isMounted) {
          setSettings(userSettings);
        }
      } else if (isMounted) {
        setSettings({
          userPreferences: {},
          calendarIntegrated: false,
        });
      }
      if (isMounted) {
        setLoading(false);
      }
    });

    return () => {
      isMounted = false; // Clean up mounted state
      unsubscribe(); // Cleanup subscription
    };
  }, []);

  // Function to update settings in context and Firebase
  const updateSettings = async (newSettings) => {
    if (JSON.stringify(settings) !== JSON.stringify(newSettings)) {
      setSettings(newSettings);
      console.log("Updated settings: ", settings);
      // const user = auth.currentUser;
      // if (user) {
      //   if (await saveUserSettings(user.uid, newSettings)) {
      //     console.log("User preferences saved");
      //   } else {
      //     console.log("Error saving user preferences");
      //   }
      // }
    }
  };

  return (
    <GlobalSettingsContext.Provider
      value={{ settings, updateSettings, loading }}
    >
      {children}
    </GlobalSettingsContext.Provider>
  );
};

// Hook to use settings context
export const useGlobalSettings = () => useContext(GlobalSettingsContext);
