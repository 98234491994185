// utils/Constants.js

// Tabs
export const TABS = {
  TAB_TODAY: "Today",
  TAB_THIS_WEEK: "This Week",
  TAB_BACKLOG: "Backlog",
};

export const COPYTOTABS = {
  TAB_TODAY: "Today",
  TAB_TOMORROW: "Tomorrow",
  TAB_NEXT_WEEK: "Next Week",
};

// Firestore collection paths
export const FIRESTORE_PATHS = {
  FIRESTORE_USERS_COLLECTION: "users",
  FIRESTORE_TODOS_COLLECTION: "todos",
};

// Sidebar icon names
export const SIDEBAR_ICON_NAMES = {
  CALENDAR_TODO: { name: "calendar-todo", title: "Dashboard" },
  HISTORY: { name: "history", title: "History" },
  PLANNING: { name: "planning", title: "Planning" },
  ANALYTICS: { name: "analytics", title: "Reports" },
  ACCOUNT: { name: "account", title: "Account" },
  LOGOUT: { name: "logout", title: "Logout" },
};

// Sidebar icon names
export const HISTORYVIEW_TABS = {
  HISTORY: "History",
  BOOKMARKS: "Bookmarks",
};

export const TIME_OPTIONS = [
  { label: "--", value: 0 },
  { label: "15 min", value: 0.25 },
  { label: "30 min", value: 0.5 },
  { label: "45 min", value: 0.75 },
  { label: "1 hour", value: 1 },
  { label: "1.5 hours", value: 1.5 },
  { label: "2 hours", value: 2 },
  { label: "3 hours", value: 3 },
  { label: "4 hours", value: 4 },
];

export const PAGINATION_ITEMS_AT_A_TIME = 15;

export const CALENDAR_DAY_START_TIME = 0;
export const CALENDAR_DAY_END_TIME = 24;
