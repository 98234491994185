// TodoSection.js

// "Today" filters for todos due on the current day.
// "This Week" filters for todos due after today and before or on the end of this week (Sunday).
// "Backlog" filters for todos due after this week.

import React, { useState, useEffect } from "react";
import TodoTabs from "./TodoTabs";
import TodoItem from "./TodoItem";
import { db } from "./firebase-config";
import { collection, addDoc, onSnapshot, query } from "firebase/firestore";
import "./TodoSection.css";
import { useUser } from "./UserContext"; // Import the useUser hook
import { extractTags } from "./utils/TodoUtils"; // Import the extractTags function
import { serverTimestamp } from "firebase/firestore"; // Import serverTimestamp. Convert this into JavaScript Date object for local timezone information.
import { orderBy, where } from "firebase/firestore";
import {
  getStartOfToday,
  getEndOfToday,
  getEndOfWeek,
} from "./utils/DateUtils"; // Import the date utility functions
import { TABS } from "./utils/Constants";
import { parseTodoDueDate } from "./utils/TodoUtils";
import HashtagFilterBar from "./HashtagFilterBar";
import { Timestamp } from "firebase/firestore";
import { addTodo } from "./firebase-utils";

function TodoSection() {
  const [todos, setTodos] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("dashboardActiveTab") || TABS.TAB_TODAY
  );
  const { currentUser } = useUser();
  const [selectedHashtags, setSelectedHashtags] = useState([]);

  useEffect(() => {
    localStorage.setItem("dashboardActiveTab", activeTab);
    if (!currentUser) return;
    const startOfToday = Timestamp.fromDate(getStartOfToday());

    const todosCollectionRef = collection(
      db,
      "users",
      currentUser.uid,
      "todos"
    );
    // You can use query and where if you need to filter the todos
    // Query that orders the tasks by dueOn, with null values first, then by createdAt
    const q = query(
      todosCollectionRef,
      where("dueOn", ">=", startOfToday),
      orderBy("dueOn", "asc"), // null values (no due date) will come first
      orderBy("createdAt", "desc") // then sort by creation date, most recent first
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const todos = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTodos(todos);
    });
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [currentUser, activeTab]);

  // Function to toggle a hashtag's selection
  const toggleHashtag = (hashtag) => {
    setSelectedHashtags((prevSelectedHashtags) =>
      prevSelectedHashtags.includes(hashtag)
        ? prevSelectedHashtags.filter((ht) => ht !== hashtag)
        : [...prevSelectedHashtags, hashtag]
    );
  };

  // Filter the todos based on the selected hashtags
  const filterTodosByHashtags = (todos, selectedHashtags) => {
    if (selectedHashtags.length === 0) return todos;
    return todos.filter((todo) =>
      todo.tags.some((tag) => selectedHashtags.includes(tag))
    );
  };

  const updateTodoInLocalState = (id, updatedFields) => {
    setTodos((prevTodos) =>
      prevTodos.map((todo) =>
        todo.id === id ? { ...todo, ...updatedFields } : todo
      )
    );
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const filterTodosByTab = (todos, activeTab) => {
    const startOfToday = getStartOfToday();
    const endOfToday = getEndOfToday();
    const endOfWeek = getEndOfWeek();

    switch (activeTab) {
      case TABS.TAB_TODAY:
        return todos.filter((todo) => {
          const dueDate = todo.dueOn?.toDate();
          return dueDate >= startOfToday && dueDate <= endOfToday;
        });
      case TABS.TAB_THIS_WEEK:
        return todos.filter((todo) => {
          const dueDate = todo.dueOn?.toDate();
          return dueDate > endOfToday && dueDate <= endOfWeek;
        });
      case TABS.TAB_BACKLOG:
        return todos.filter((todo) => {
          const dueDate = todo.dueOn?.toDate();
          return !dueDate || dueDate > endOfWeek;
        });
      default:
        // This should not happen, but it's a good practice to have a default return
        return todos;
    }
  };

  const filteredTodos = filterTodosByTab(todos, activeTab);
  const hashtagFilteredTodos = filterTodosByHashtags(
    filteredTodos,
    selectedHashtags
  );

  const handleAddTodo = async (e) => {
    e.preventDefault();
    const trimmedInputValue = inputValue.trim();
    if (!trimmedInputValue) return;
    const { modifiedTitle, dueOn: extractedDueOn } =
      parseTodoDueDate(trimmedInputValue); // Parse the dueOn date from title and also remove it from title
    if (!modifiedTitle) return; // Or handle the empty title case as needed
    const tags = extractTags(modifiedTitle); // Extract tags from the input value

    const newTodo = {
      title: modifiedTitle,
      completed: false,
      tags: tags, // Include the extracted tags
      createdAt: serverTimestamp(),
      dueOn: extractedDueOn ? extractedDueOn : serverTimestamp(), // Set the dueOn to today's date
    };
    setInputValue("");
    await addTodo(currentUser.uid, newTodo);
  };

  const handleSelectAll = () => {
    const allTags = todos
      .flatMap((todo) => extractTags(todo.title))
      .filter((v, i, a) => a.indexOf(v) === i);
    setSelectedHashtags(allTags);
  };

  const handleClearSelection = () => {
    setSelectedHashtags([]);
  };

  return (
    <div className="todo-section">
      <div className="todo-sub-section">
        <div className="top-head">
          <TodoTabs
            tabs={[TABS.TAB_TODAY, TABS.TAB_THIS_WEEK, TABS.TAB_BACKLOG]}
            onTabSelect={handleTabSelect}
            activeTab={activeTab}
          />
          <div className="todo-form-wrapper">
            <form onSubmit={handleAddTodo} className="todo-form">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Add a new todo"
                required
              />
              {/* <button className="primary-button" type="submit">
                Add
              </button> */}
            </form>
          </div>
          <HashtagFilterBar
            todos={todos}
            selectedHashtags={selectedHashtags}
            toggleHashtag={toggleHashtag}
            onSelectAll={handleSelectAll}
            onClearSelection={handleClearSelection}
          />
          <div className="todo-items-list">
            {hashtagFilteredTodos.map((todo) => (
              <TodoItem
                key={todo.id}
                id={todo.id}
                title={todo.title}
                content={todo.content}
                completed={todo.completed}
                estimateTimeInDB={todo.estimatedTime}
                actualTimeInDB={todo.actualTime}
                dueOnInDB={todo.dueOn?.toDate() ?? null}
                bookmarked={todo.bookmarked}
                isRepeating={todo.isRepeating}
                repeatingCadence={todo.repeatingCadence}
                tags={todo.tags}
                updateTodoInLocalState={updateTodoInLocalState}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TodoSection;
