// SignIn.js
import React, { useState } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "./firebase-config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./SignIn.css";

const SignIn = () => {
  const navigate = useNavigate();

  // State hooks for email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Additional state hooks for sign in and sign up screen
  const [isSigningUp, setIsSigningUp] = useState(false); // To toggle between sign-in and sign-up view
  const [loading, setLoading] = useState(false); // To show a loader when creating a user

  // Function to handle Google Sign-In
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      // User signed in successfully.
      console.log("User signed in with Google");
      navigate("/dashboard");
    } catch (error) {
      // Handle Errors here.
      console.error("Error signing in with Google:", error.message);
    }
  };

  // Function to handle Email/Password Sign-In and Sign-Up
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loader

    if (!isSigningUp) {
      // Handle sign-in
      try {
        await signInWithEmailAndPassword(auth, email, password);
        console.log("User signed in with email and password");
        // await setDoc(doc(db, "users", currentUser.uid), {
        //   // Add any initial data you want in the user's document
        // });
        navigate("/dashboard");
      } catch (error) {
        console.error("Error signing in:", error.message);
        if (error.code === "auth/user-not-found") {
          toast("User not found. Please sign up"); // Notify user
        } else {
          toast("Sign-in failed. Please try again."); // Notify user
        }
        // TODO: Handle other errors (
      }
    } else {
      // Handle sign-up
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        console.log("User created and signed in with email and password");

        navigate("/dashboard");
      } catch (error) {
        console.error("Error signing up:", error.message);
        if (error.code === "auth/email-already-in-use") {
          toast("Email already in use. Please sign in"); // Notify user
        } else {
          toast("Sign-up failed. Please try again."); // Notify user
        }
      }
    }

    setLoading(false); // Hide loader after sign-in/up
  };

  return (
    <div className="sign-in-container">
      {loading ? (
        <div>Loading...</div> // Placeholder loader, replace with your loader component
      ) : isSigningUp ? (
        // Sign-Up View
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button className="signIn-btn" type="submit">
            Create Account
          </button>
          <button
            className="signUp-btn"
            type="button"
            onClick={() => setIsSigningUp(false)}
          >
            Back to <span>Sign In</span>
          </button>
        </form>
      ) : (
        // Sign-In View
        <>
          <button
            className="google-login text-center"
            onClick={handleGoogleSignIn}
          >
            Sign in with Google
          </button>

          <div className="separator">
            <span>or</span>
          </div>

          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <a className="forgot-password">Forgot password</a>
            <div className="form-footer">
              <button className="signIn-btn" type="submit">
                Sign In
              </button>
              <button
                className="signUp-btn"
                type="button"
                onClick={() => setIsSigningUp(true)}
              >
                For Sign Up<span>Register Here</span>
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default SignIn;
