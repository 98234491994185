import React from "react";
import TodoSection from "./TodoSection";
import "./Dashboard.css";
import CalendarView from "./CalendarView";

function Dashboard() {
  return (
    <div className="dashboard">
      <CalendarView />
      <TodoSection />
      {/* Include any other components that belong to the dashboard view here */}
    </div>
  );
}

export default Dashboard;
