// AccountView.js
import React, { useEffect, useState } from "react";
import { useUser } from "./UserContext";
import { useGlobalSettings } from "./context/GlobalSettingsContext";
import { functions } from "./firebase-config";
import { httpsCallable } from "firebase/functions";
import { getRedirectUri } from "./utils/GeneralUtils";

import "./AccountView.css";
function AccountView() {
  const { currentUser } = useUser();
  const { settings, updateSettings } = useGlobalSettings();

  const [loading, setLoading] = useState(false);

  // Function to initiate OAuth flow
  const initiateOAuthFlow = async () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = encodeURIComponent(getRedirectUri());
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/calendar"
    );
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&prompt=consent`;

    window.location.href = authUrl; // Redirect the user
  };

  const handleDisableIntegration = async () => {
    console.log("Disable Google Calendar integration");
    const revokeTokens = functions.httpsCallable("revokeTokens");
    setLoading(true);

    try {
      const result = await revokeTokens({ userId: currentUser?.uid });
      if (result.data.success) {
        console.log("Integration disabled and tokens revoked successfully.");
        updateSettings({ ...settings, calendarIntegrated: false });
      } else {
        console.error("Failed to revoke tokens: ", result.data.message);
      }
    } catch (error) {
      console.error("Error disabling integration:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Placeholder for any logic to check if the user has completed the OAuth flow
  }, []);

  return (
    <div className="account-view">
      <h1>My Account</h1>
      <p>
        <strong>Name:</strong> {currentUser?.displayName || "N/A"}
      </p>
      <p>
        <strong>Email:</strong> {currentUser?.email || "N/A"}
      </p>
      {loading ? (
        <p>Loading...</p>
      ) : settings?.calendarIntegrated ? (
        <button onClick={handleDisableIntegration}>
          Disable Google Calendar Integration
        </button>
      ) : (
        <button onClick={initiateOAuthFlow}>
          Authorize Google Calendar Integration
        </button>
      )}
    </div>
  );
}

export default AccountView;
