// ./utils/TodoUtils.js
import { parse, isBefore, addYears, format, startOfDay } from "date-fns";
import { Timestamp } from "firebase/firestore";

// Function to extract tags from a string (like a todo title)
export function extractTags(text) {
  // const lowerCaseText = text.toLowerCase(); // Convert text to lowercase for case-insensitive matching
  const tagRegex = /#[\w]+/g; // Matches any word following a '#'
  const foundTags = text.match(tagRegex) || [];
  return foundTags.map((tag) => tag.slice(1)); // Removes the '#' from the tag
}

/**
 * Parses natural language dates from a todo title and adjusts the dueOn date.
 * @param {string} titleWithDate The todo title possibly containing a date in natural language.
 * @returns {Object} An object containing the modified title and the dueOn date.
 */
export function parseTodoDueDate(titleWithDate) {
  // Regular expression to find dates in the format '9Dec', '12Aug', without year
  const dateRegex = /\b(\d{1,2}[A-Za-z]{3})\b/;
  const match = titleWithDate.match(dateRegex);
  let dueOn = null;

  if (match) {
    // Extract the matched date string
    const dateString = match[0];
    // Attempt to parse the date string to a Date object for the current year
    let parsedDate = parse(dateString, "dMMM", new Date());

    // Check if the parsed date has already passed this year; if so, add one year
    if (isBefore(parsedDate, startOfDay(new Date()))) {
      parsedDate = addYears(parsedDate, 1);
    }

    // Remove the date string from the title
    titleWithDate = titleWithDate.replace(dateRegex, "").trim();

    // Format the dueOn date to a simple string format, adjust as needed for Firestore
    dueOn = format(parsedDate, "yyyy-MM-dd");
  }

  // Return both the modified title and the dueOn date (if found)
  return {
    modifiedTitle: titleWithDate,
    dueOn: dueOn ? Timestamp.fromDate(new Date(dueOn)) : null,
  };
}
