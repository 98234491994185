// TodoItem.js

// To add rich text editing capabilities to each todo item, you would typically use a rich text editor library. One popular choice is react-quill, a React wrapper for the Quill text editor.

import React, { useState } from "react";
import "./TodoItem.css";
import { useUser } from "./UserContext"; // Import the useUser hook
import { Timestamp } from "firebase/firestore"; // Import Timestamp to convert JavaScript Date objects to Firestore Timestamp objects
import {
  FaPencilAlt,
  FaHourglassStart,
  FaHourglassEnd,
  FaRegCalendarAlt,
  FaTrash,
  FaRegBookmark,
  FaBookmark,
} from "react-icons/fa"; // Using React Icons for the pencil icon
import TodoItemContent from "./TodoItemContent"; // Import the new component
import { extractTags, parseTodoDueDate } from "./utils/TodoUtils"; // Import the extractTags function
import TimeDropdown from "./TimeDropdown"; // You would need to create this component
import { formatTime } from "./utils/DateUtils"; // Import the formatTime function
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TodoContentModal from "./TodoContentModal"; // Import the new component
import {
  updateTodoTitleAndTags,
  updateTodoTime,
  deleteTodo,
  updateTodo,
} from "./firebase-utils";
import { toast } from "react-toastify";
import MobileTodoOptionsModal from "./MobileTodoOptionsModal";

function TodoItem({
  id,
  title,
  content,
  completed,
  estimateTimeInDB,
  actualTimeInDB,
  dueOnInDB,
  bookmarked,
  isRepeating,
  repeatingCadence,
  tags,
  updateTodoInLocalState,
}) {
  const { currentUser } = useUser();
  const [isTitleEditing, setIsTitleEditing] = useState(false); // todo title editing state
  const [editableTitle, setEditableTitle] = useState(title); // todo title value
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileTodoOptionsModalOpen, setIsMobileTodoOptionsModalOpen] =
    useState(false);

  const [estimateTime, setEstimateTime] = useState(estimateTimeInDB);
  const [actualTime, setActualTime] = useState(actualTimeInDB);

  const [isBookmarked, setIsBookmarked] = useState(bookmarked);

  const [showEstimatedDropdown, setShowEstimatedDropdown] = useState(false);
  const [showActualDropdown, setShowActualDropdown] = useState(false);

  const [isDueDatePickerOpen, setDueDatePickerOpen] = useState(false);
  const [dueOn, setDueOn] = useState(dueOnInDB); // Convert Firestore timestamp to JavaScript Date object

  // Function to toggle the completed status of the todo
  const toggleCompleted = async () => {
    const newCompletedStatus = !completed;
    let updatedTodo = {
      completed: newCompletedStatus,
    };
    performTodoUpdate(updatedTodo);
  };

  async function performTodoUpdate(updatedTodo) {
    var wasUpdated = await updateTodo(currentUser.uid, id, updatedTodo);
    if (!wasUpdated) {
      toast.error(
        "Failed to update. Please try again in some time. If problem persists, please contact support"
      );
    }
  }

  // Modified toggleBookmark function with optimistic UI updates
  const toggleBookmark = async () => {
    const newBookmarkStatus = !isBookmarked;

    // Optimistically update the UI
    setIsBookmarked(newBookmarkStatus);
    let updatedTodo = {
      bookmarked: newBookmarkStatus,
    };
    performTodoUpdate(updatedTodo);
  };

  // Function to make the title editable or not
  const toggleTitleEdit = () => setIsTitleEditing(!isTitleEditing);

  // Toggles for dropdown display
  const toggleEstimatedDropdown = () => {
    setShowEstimatedDropdown(!showEstimatedDropdown);
  };

  const toggleActualDropdown = () => {
    setShowActualDropdown(!showActualDropdown);
  };

  // Function to change the content of the title based on user input
  const handleTitleChange = (e) => setEditableTitle(e.target.value);

  // Function to handle date change
  const handleDateChange = async (date) => {
    setDueDatePickerOpen(false);
    setDueOn(date);

    let updatedTodo = {
      dueOn: date,
    };
    performTodoUpdate(updatedTodo);
    updateTodoInLocalState(id, { dueOn: Timestamp.fromDate(date) });
  };

  // Function to disable edit mode and update the Firestore
  const updateTitle = async () => {
    const originalTitle = title; // Save the original title before making any changes
    if (title !== editableTitle) {
      setIsTitleEditing(false); // Optimistically disable editing mode

      const { modifiedTitle, dueOn: extractedDueOn } =
        parseTodoDueDate(editableTitle);
      const tags = extractTags(modifiedTitle);

      const updatedTodo = {
        title: modifiedTitle,
      };

      if (tags.length !== 0) {
        updateTodo.tags = tags;
      }

      // Conditionally add the dueOn field if newDueOn is not null
      if (extractedDueOn !== null) {
        updatedTodo.dueOn = extractedDueOn;
      }

      updateTodoInLocalState(id, updatedTodo); // Optimistically update the UI

      try {
        const wasUpdated = await updateTodoTitleAndTags(
          currentUser.uid,
          id,
          modifiedTitle,
          tags,
          extractedDueOn ? extractedDueOn : dueOnInDB // Update dueOn if a new date was extracted
        );

        if (!wasUpdated) throw new Error("Failed to update title."); // Simulate update failure
      } catch (error) {
        // Revert the UI changes in case of an error
        setIsTitleEditing(true); // Enable editing mode again if needed
        updateTodoInLocalState(id, {
          title: originalTitle,
          tags: extractTags(originalTitle),
          dueOn: dueOnInDB,
        }); // Revert to the original title
        console.error(error);
        toast.error("Failed to update the todo item title. Please try again."); // Show error toast
      }
    } else {
      setIsTitleEditing(false); // Disable editing mode if the title hasn't changed
    }
  };

  const handlePencilClick = () => setIsModalOpen(true); // Open the modal when the pencil icon is clicked

  // Function to handle the Enter key on the input field
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      updateTitle();
    }
  };

  const handleSaveContent = async (updatedContent) => {
    let updatedTodo = {
      content: updatedContent,
    };
    performTodoUpdate(updatedTodo);

    updateTodoInLocalState(id, { content: updatedContent });
    setIsModalOpen(false);
  };

  const handleTimeSelect = async (selectedTime, type) => {
    // Logic to update the selected time state and save to Firebase

    const wasUpdated = await updateTodoTime(
      currentUser.uid,
      id,
      selectedTime,
      type
    );
    if (wasUpdated) {
      if (type === "estimate") {
        setEstimateTime(selectedTime);
      } else {
        setActualTime(selectedTime);
      }
      setShowEstimatedDropdown(false);
      setShowActualDropdown(false);
    } else {
      console.error(`Failed to update ${type} time.`);
    }
  };

  // Function to call deleteTodo from firebase-utils.js
  const handleDelete = async () => {
    // Show a toast with an Undo option. Delay actual deletion.
    toast.dismiss(); // Dismiss all toasts before showing a new one to prevent duplicates

    let deleteRevoked = false; // Flag to track if deletion should proceed

    toast.info(
      ({ closeToast }) => (
        <div>
          Todo will be deleted.
          <button
            onClick={() => {
              deleteRevoked = true; // Prevent deletion
              closeToast(); // Dismiss the toast
            }}
            style={{
              marginLeft: "10px",
              color: "blue",
              background: "none",
              border: "none",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Undo
          </button>
        </div>
      ),
      {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          if (!deleteRevoked) {
            actuallyDeleteTodo();
          }
        },
      }
    );

    // Define the actual deletion logic here, to be executed if not undone
    const actuallyDeleteTodo = async () => {
      const wasDeleted = await deleteTodo(currentUser.uid, id);
      if (wasDeleted) {
        updateTodoInLocalState(id, null, true); // Remove the todo from local state
      } else {
        console.error("Failed to delete todo.");
      }
    };
  };

  return (
    <div className={`todo-item ${completed ? "completed" : ""}`}>
      <input
        type="checkbox"
        id={`todo-item-checkbox-${id}`}
        className="todo-item-checkbox"
        checked={completed}
        onChange={toggleCompleted}
      />
      <label
        htmlFor={`todo-item-checkbox-${id}`}
        className="custom-checkbox-label"
      ></label>
      {isTitleEditing ? (
        <textarea
          // type="text"
          className="editable-title-input"
          value={editableTitle}
          onChange={handleTitleChange} // Set the value of editableTitle to the input's current value
          onBlur={updateTitle} // Save the title when the input loses focus
          onKeyDown={handleKeyDown} // Save the title when Enter is pressed
          autoFocus
          rows="2" // Starts with two lines, but you can adjust this as needed
          style={{ resize: "none" }} // Prevents manual resizing of the textarea
        />
      ) : (
        <span
          className="todo-title"
          onClick={toggleTitleEdit}
          title="Edit Todo"
        >
          {title}
        </span>
      )}

      <div className="todo-options">
        {/* Mobile-only "Open" button */}
        <button
          className="open-btn-mobile"
          onClick={() => setIsMobileTodoOptionsModalOpen(true)}
        >
          Open
        </button>
        {/* Modal for viewing/editing todo details */}
        {isMobileTodoOptionsModalOpen && (
          <MobileTodoOptionsModal
            isOpen={isMobileTodoOptionsModalOpen}
            onClose={() => setIsMobileTodoOptionsModalOpen(false)}
          >
            <div className="mmodal-content-inner">
              {/* Task title */}
              <div className="todo-title-mobile">{title}</div>
              <div className="todo-dueOn-mobile">
                {dueOn.toLocaleDateString()}
              </div>
              {/* Row for quick actions */}
              <div className="todo-actions-mobile">
                {/* Example action - Mark as done */}
                <button onClick={toggleCompleted}>Toggle Completed</button>
                <button onClick={toggleBookmark}>Toggle Bookmark</button>
                <button onClick={handleDelete}>Delete</button>
                {/* Add other actions here: Bookmark, Delete */}
              </div>
              {/* Row for time adjustments */}
              <div className="time-adjustments-mobile">
                <TimeDropdown
                  className="time-dropdown-mobile"
                  onSelect={(time) => handleTimeSelect(time, "estimate")}
                  placeholder="Est. Time"
                />
                <TimeDropdown
                  className="time-dropdown-mobile"
                  onSelect={(time) => handleTimeSelect(time, "actual")}
                  placeholder="Actual Time"
                />
              </div>
              {/* Rich text editor for todo content */}
              <TodoItemContent
                className="todo-content-mobile"
                content={content}
                onSaveContent={handleSaveContent}
              />
            </div>
          </MobileTodoOptionsModal>
        )}
        <FaPencilAlt
          className="edit-icon"
          onClick={handlePencilClick}
          title="Edit Details"
        />{" "}
        {isBookmarked ? (
          <FaBookmark
            className="bookmark-icon"
            onClick={toggleBookmark}
            title="Bookmarked"
          />
        ) : (
          <FaRegBookmark
            className="bookmark-icon"
            onClick={toggleBookmark}
            title="Bookmark"
          />
        )}
        <div className="time-icons">
          <FaHourglassStart
            className="time-icon"
            onClick={toggleEstimatedDropdown}
            title="Estimated time"
          />
          <span
            className="selected-time"
            onClick={toggleEstimatedDropdown}
            title="Estimated time"
          >
            {formatTime(estimateTime)}
          </span>
          {showEstimatedDropdown && (
            <TimeDropdown
              onSelect={(time) => handleTimeSelect(time, "estimate")}
              placeholder={formatTime(estimateTime)}
            />
          )}

          <FaHourglassEnd
            className="time-icon"
            onClick={toggleActualDropdown}
            title="Actual time"
          />
          <span
            className="selected-time"
            onClick={toggleActualDropdown}
            title="Actual time"
          >
            {formatTime(actualTime)}
          </span>
          {showActualDropdown && (
            <TimeDropdown
              onSelect={(time) => handleTimeSelect(time, "actual")}
              placeholder={formatTime(actualTime)}
            />
          )}
        </div>
        <FaRegCalendarAlt
          className="calendar-icon"
          onClick={() => setDueDatePickerOpen(!isDueDatePickerOpen)}
          title="Change due date"
        />
        {isDueDatePickerOpen && (
          <ReactDatePicker selected={dueOn} onChange={handleDateChange} />
        )}
        <FaTrash
          className="delete-icon"
          onClick={handleDelete}
          title="Delete todo"
        />{" "}
        {/* Bin icon for deletion */}
      </div>

      {/* The modal component that will conditionally render based on isModalOpen */}
      <TodoContentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <h3>{title}</h3>
        <TodoItemContent content={content} onSaveContent={handleSaveContent} />
      </TodoContentModal>
    </div>
  );
}

export default TodoItem;
