// HistoryViewTodoItem.js
import React, { useState } from "react";
import {
  format,
  startOfToday,
  endOfToday,
  startOfWeek,
  addWeeks,
  addDays,
} from "date-fns"; // For formatting dates
import "./HistoryViewTodoItem.css";
import DOMPurify from "dompurify";
import TodoContentModal from "./TodoContentModal";
import { addTodo, updateTodo } from "./firebase-utils"; // Import the function to add a task
import { useUser } from "./UserContext"; // Import the useUser hook
import { toast } from "react-toastify";
import { COPYTOTABS } from "./utils/Constants";
import { BsThreeDotsVertical } from "react-icons/bs"; // Import 3-dot icon from 'react-icons'
import { TIME_OPTIONS } from "./utils/Constants";
import HistoryViewTodoOptionsModal from "./HistoryViewTodoOptionsModal";
import TodoItemContent from "./TodoItemContent"; // Import the new component

const HistoryViewTodoItem = ({ tasks = [], updateTodoInLocalState }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const { currentUser } = useUser();
  const [
    isHistoryViewTodoOptionsModalOpen,
    setIsHistoryViewTodoOptionsModalOpen,
  ] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);

  // Function to handle task click
  const handleTaskClick = (task) => {
    setSelectedTask(task);
  };

  // Function to close the modal
  const handleCloseHistoryViewTodoOptionsModal = () => {
    setIsHistoryViewTodoOptionsModalOpen(false);
  };

  // Function to close the modal
  const handleCloseTodoContentModal = () => {
    setSelectedTask(null);
  };

  const handleEditClick = (task) => {
    setTaskToEdit(task);
    setIsHistoryViewTodoOptionsModalOpen(true);
  };

  const handleCopyTask = (task, destination) => {
    if (!destination) return;
    // Initialize a new task with mandatory fields only, explicitly avoiding 'undefined' values
    let newTask = {
      ...task,
      completed: false, // Always mark as incomplete
    };

    // Delete the 'id' field if present to avoid conflicts in Firebase (assuming it auto-generates IDs)
    if ("id" in newTask) {
      delete newTask.id;
    }

    // Conditionally add 'dueOn' for 'Today' and 'This Week' destinations
    if (destination === COPYTOTABS.TAB_TODAY) {
      newTask.dueOn = endOfToday();
    } else if (destination === COPYTOTABS.TAB_TOMORROW) {
      newTask.dueOn = addDays(endOfToday(), 1);
    } else if (destination === COPYTOTABS.TAB_NEXT_WEEK) {
      newTask.dueOn = addDays(addWeeks(startOfWeek(new Date()), 1), 1);
    }

    // Conditionally add 'content' if it exists and is not just whitespace
    if (task.content && task.content.trim() !== "") {
      newTask.content = task.content;
    }

    // Now, newTask has only the properties that are explicitly set, avoiding 'undefined' values

    let isCopied = addTodo(currentUser.uid, newTask);
    isCopied && toast.success("Task copied successfully!");
  };

  const handleSaveChanges = async (updatedTask) => {
    try {
      setIsHistoryViewTodoOptionsModalOpen(false);
      setTaskToEdit(null);
      await updateTodo(currentUser.uid, updatedTask.id, updatedTask);
      updateTodoInLocalState(updatedTask.id, updatedTask);
      toast.success("Task updated successfully!");
    } catch (error) {
      toast.error("Error updating task.");
      console.error("Error updating task:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTaskToEdit({ ...taskToEdit, [name]: value });
  };

  // Handle change event for checkbox inputs
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setTaskToEdit((prev) => ({ ...prev, [name]: checked }));
  };

  // Handle change event for time dropdowns
  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    setTaskToEdit((prev) => ({ ...prev, [name]: parseFloat(value) }));
  };

  // Group tasks by their 'dueOn' date
  const groupedTasks = tasks.reduce((acc, task) => {
    // Ensure task.dueOn is a Date object. Adjust according to your data structure.
    const date = format(task.dueOn.toDate(), "yyyy-MM-dd");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(task);
    return acc;
  }, {});

  const handleSaveContent = async (updatedContent) => {
    let updatedTodo = {
      content: updatedContent,
    };
    performTodoUpdate(updatedTodo);

    updateTodoInLocalState(selectedTask.id, { content: updatedContent });
    setSelectedTask(null);
  };

  async function performTodoUpdate(updatedTodo) {
    var wasUpdated = await updateTodo(
      currentUser.uid,
      selectedTask.id,
      updatedTodo
    );
    if (!wasUpdated) {
      toast.error(
        "Failed to update. Please try again in some time. If problem persists, please contact support"
      );
    }
  }

  return (
    <div className="m-2">
      {Object.entries(groupedTasks).map(([date, tasksForDate]) => (
        <div key={date}>
          <p className="task-date mt-4">
            {format(new Date(date), "dd MMMM yyyy")}
          </p>
          {tasksForDate.map((task) => (
            <div
              key={task.id}
              className="history-task-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex">
                <input type="checkbox" checked={task.completed} readOnly />
                <div
                  className="task-details-wrapper"
                  onClick={() => handleTaskClick(task)}
                >
                  <p className="task-title">{task.title}</p>
                </div>
              </div>
              <div className="task-options-wrapper d-flex">
                <div>
                  {/* Dropdown menu for task duplication */}
                  <select
                    className="copy-task-dropdown"
                    onChange={(e) => handleCopyTask(task, e.target.value)}
                  >
                    <option value="">Copy to...</option>
                    <option value={COPYTOTABS.TAB_TODAY}>
                      {COPYTOTABS.TAB_TODAY}
                    </option>
                    <option value={COPYTOTABS.TAB_TOMORROW}>
                      {COPYTOTABS.TAB_TOMORROW}
                    </option>
                    <option value={COPYTOTABS.TAB_NEXT_WEEK}>
                      {COPYTOTABS.TAB_NEXT_WEEK}
                    </option>
                  </select>
                </div>
                <div className="task-options">
                  <BsThreeDotsVertical onClick={() => handleEditClick(task)} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
      {/* Modal for task details - you can create and style this component as needed */}
      {selectedTask && (
        <TodoContentModal
          isOpen={!!selectedTask}
          onClose={handleCloseTodoContentModal}
        >
          <div className="task-details">
            <h3>{selectedTask.title}</h3>
            {/* Display other task details here */}
            <TodoItemContent
              content={selectedTask.content}
              onSaveContent={handleSaveContent}
            />
            {/* Add more task details if needed */}
          </div>
        </TodoContentModal>
      )}

      {/* Modal for editing task details */}
      {isHistoryViewTodoOptionsModalOpen && taskToEdit && (
        <HistoryViewTodoOptionsModal
          isOpen={isHistoryViewTodoOptionsModalOpen}
          onClose={handleCloseHistoryViewTodoOptionsModal}
        >
          <div className="task-edit-modal">
            {/* <input
              type="text"
              name="title"
              value={taskToEdit.title || ""}
              onChange={handleInputChange}
            /> */}
            {/* <textarea
              name="content"
              value={taskToEdit.content || ""}
              onChange={handleInputChange}
            /> */}
            {/* Include other fields for editing as necessary */}
            <label>
              Completed:
              <input
                type="checkbox"
                name="completed"
                checked={taskToEdit.completed || false}
                onChange={handleCheckboxChange}
              />
            </label>

            <label>
              Bookmarked:
              <input
                type="checkbox"
                name="bookmarked"
                checked={taskToEdit.bookmarked || false}
                onChange={handleCheckboxChange}
              />
            </label>

            <label>
              Estimate Time:
              <select
                name="estimateTime"
                value={taskToEdit.estimateTime || 0}
                onChange={handleTimeChange}
              >
                {TIME_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Actual Time:
              <select
                name="actualTime"
                value={taskToEdit.actualTime || 0}
                onChange={handleTimeChange}
              >
                {TIME_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
            <button onClick={() => handleSaveChanges(taskToEdit)}>
              Save Changes
            </button>
            <button onClick={handleCloseHistoryViewTodoOptionsModal}>
              Cancel
            </button>
          </div>
        </HistoryViewTodoOptionsModal>
      )}
    </div>
  );
};

export default HistoryViewTodoItem;
