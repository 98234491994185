import React, { useState, useEffect } from "react";
import {
  Scheduler,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import "./CalendarView.css";
import { getAccessToken } from "./firebase-utils";
import CalendarViewIntegrationModal from "./CalendarViewIntegrationModal";
import { useUser } from "./UserContext";
import { useGlobalSettings } from "./context/GlobalSettingsContext";
import {
  CALENDAR_DAY_START_TIME,
  CALENDAR_DAY_END_TIME,
} from "./utils/Constants";
import { CurrentTimeIndicator } from "@devexpress/dx-react-scheduler-material-ui";
import CustomTimeIndicator from "./CustomTimeIndicator";
import { toast } from "react-toastify";

const CalendarView = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [allAppointments, setAllAppointments] = useState([]);
  const [showIntegrationPopup, setShowIntegrationPopup] = useState(false);
  const { currentUser } = useUser();
  const { settings, updateSettings } = useGlobalSettings();

  useEffect(() => {
    const checkAndLoadEvents = async () => {
      const accessToken = await getAccessToken(currentUser.uid);
      if (!accessToken) {
        setShowIntegrationPopup(true);
        updateSettings({
          ...settings,
          calendarIntegrated: false,
        });
      } else {
        updateSettings({
          ...settings,
          calendarIntegrated: true,
          accessToken: accessToken,
        });
        loadEvents(accessToken);
      }
    };

    if (currentUser?.uid) {
      checkAndLoadEvents();
    }
  }, [currentUser]);

  const loadEvents = async (accessToken) => {
    // Function to load events from Google Calendar
    try {
      const fetchedEvents = await fetchCalendarEvents(accessToken);
      const appointments = fetchedEvents.map((event) => ({
        startDate: event.start.dateTime || event.start.date,
        endDate: event.end.dateTime || event.end.date,
        title: event.summary,
      }));
      setAllAppointments(appointments);
    } catch (error) {
      console.error("Error fetching calendar events:", error);
    }
  };

  async function fetchCalendarEvents(accessToken) {
    const calendarId = "primary"; // Primary calendar
    const timeMin = new Date();
    timeMin.setHours(0, 0, 0, 0); // Start of current day
    const timeMax = new Date(timeMin);
    timeMax.setDate(timeMax.getDate() + (7 - timeMax.getDay())); // End of current week
    const response = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(
        calendarId
      )}/events?timeMin=${timeMin.toISOString()}&timeMax=${timeMax.toISOString()}&singleEvents=true&orderBy=startTime`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (!response.ok) {
      toast("Failed to load calendar events!");
      throw new Error("Failed to fetch calendar events");
    }

    const data = await response.json();
    return data.items;
  }

  return (
    <div className="calendar-view">
      <Scheduler data={allAppointments}>
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={setCurrentDate}
        />
        <DayView
          startDayHour={CALENDAR_DAY_START_TIME}
          endDayHour={CALENDAR_DAY_END_TIME}
        />
        <Appointments />
        <CurrentTimeIndicator
          indicatorComponent={CustomTimeIndicator} // Use the custom indicator component
        />
        <Toolbar />
        <DateNavigator />
      </Scheduler>
      {showIntegrationPopup && (
        <CalendarViewIntegrationModal
          className="integrate-calendar-modal"
          onClose={() => setShowIntegrationPopup(false)} // Pass a method to close the modal
        >
          <div className="modal-content">
            Integrate Google Calendar by going to the profile section.
            <button
              className="primary-button integrate-calendar-button"
              onClick={() => setShowIntegrationPopup(false)}
            >
              Ok
            </button>
          </div>
        </CalendarViewIntegrationModal>
      )}
    </div>
  );
};

export default CalendarView;
