//TimeDropdown.js
import React from "react";
import "./TimeDropdown.css";
import { TIME_OPTIONS } from "./utils/Constants";

const TimeDropdown = ({ onSelect, placeholder }) => {
  return (
    <select
      onChange={(e) => onSelect(e.target.value)}
      defaultValue=""
      className="time-dropdown"
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {TIME_OPTIONS.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default TimeDropdown;
