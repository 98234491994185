import { styled } from "@mui/material/styles";

const Line = styled("div")(({ theme }) => ({
  height: "1.5px",
  background: "#ff4081", // Use the theme's primary color or any color you want
  width: "100%",
  position: "absolute",
  top: "50%", // Center vertically in the parent container
  transform: "translateY(-50%)", // Ensure it is centered correctly
  zIndex: 10, // Add this line to set z-index for Line
}));

const Circle = styled("div")(({ theme }) => ({
  width: "12px", // Width of the circle
  height: "12px", // Height of the circle
  background: "#ff4081", // Use the theme's primary color or any color you want
  borderRadius: "50%",
  position: "absolute",
  top: "50%", // Center vertically in the parent container
  left: "-6px", // Half of the width to align the circle center with the line start
  transform: "translateY(-50%)", // Ensure it is centered correctly
  zIndex: 11, // Add this line to set z-index for Line
}));

// Usage in the custom indicator component
const CustomTimeIndicator = ({ x, ...restProps }) => {
  return (
    <div {...restProps}>
      <Line />
      <Circle style={{ left: `${x}px` }} />
    </div>
  );
};

export default CustomTimeIndicator;
