// OAuthCallback.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import axios from "axios"; // Make sure to install axios for HTTP requests
import { getRedirectUri } from "./utils/GeneralUtils";
import { saveTokenstoFirestore } from "./firebase-utils";

function OAuthCallback() {
  const navigate = useNavigate();
  const { currentUser } = useUser();

  const exchangeCodeForTokens = async (code) => {
    // const oauthCallbackFunction = httpsCallable(functions, "oauthCallback");
    // oauthCallbackFunction({
    //   code: code,
    //   redirectUri: getRedirectUri(),
    // })
    //   .then((result) => {
    //     console.log(result.tokens);
    //     setTokens(result.tokens);
    //   })
    //   .catch((error) => {
    //     console.error("Error exchanging code for tokens:", error);
    //   });

    try {
      const response = await axios.post("https://oauth2.googleapis.com/token", {
        code: code,
        client_id:
          "812753310013-vg86iam7balpt9leo9uo8t48qq5ibuvi.apps.googleusercontent.com", // Replace with your actual client ID
        client_secret: "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", // Replace with your actual client secret
        redirect_uri: getRedirectUri(), // Replace with your actual redirect URI
        grant_type: "authorization_code",
      });
      saveTokenstoFirestore(currentUser.uid, response.data);
      navigate("/dashboard"); // Navigate to dashboard after successful token exchange
    } catch (error) {
      console.error("Error exchanging code for tokens:", error);
      navigate("/login"); // Redirect to login on error
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    if (currentUser && code) {
      exchangeCodeForTokens(code)
        .then(() => navigate("/dashboard")) // Navigate to the dashboard after successful exchange
        .catch((error) => {
          console.error("Error exchanging code for tokens:", error);
          navigate("/login"); // Redirect to login on failure
        });
    } else {
      // No code or currentUser, navigate to login
      navigate("/login");
    }
  }, [navigate, currentUser]);

  return <div className="loading-container d-flex">Loading...</div>; // Show a loading indicator
}

export default OAuthCallback;
