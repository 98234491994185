import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "./firebase-config";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  // Add a state to track if the auth check has been completed
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAuthInitialized(true); // Set true once we get the auth state
    });
    return unsubscribe; // This is the cleanup function.
  }, []);

  return (
    <UserContext.Provider
      value={{ currentUser, setCurrentUser, authInitialized }}
    >
      {children}
    </UserContext.Provider>
  );
};
