import React from "react";
import ReactDOM from "react-dom";
import "./HistoryViewTodoOptionsModal.css"; // Ensure this CSS file includes styles for your close button

const HistoryViewTodoOptionsModal = ({ children, isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  // This function can be enhanced to save content if needed before closing
  const handleClose = () => {
    // Invoke any save function here if necessary
    onClose();
  };

  return ReactDOM.createPortal(
    <div className="hvmodal-overlay" onClick={handleClose}>
      <div className="hvmodal-content" onClick={(e) => e.stopPropagation()}>
        {children}
        <button className="hvmodal-close-btn" onClick={handleClose}>
          &times;
        </button>{" "}
        {/* Cross button */}
      </div>
    </div>,
    document.body
  );
};

export default HistoryViewTodoOptionsModal;
