//TodoTabs.js
import React from "react";
import "./TodoTabs.css"; // Your CSS here

function TodoTabs({ tabs, onTabSelect, activeTab }) {
  // Handle tab click
  const handleTabClick = (tab) => {
    onTabSelect(tab);
  };

  return (
    <div className="todo-tabs">
      {/* <span className="heading-todo">Todos</span> */}
      <div className="todo-tabs-wrapper">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`tab ${activeTab === tab ? "active" : ""} button-tab`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
}

export default TodoTabs;
