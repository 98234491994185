// firebase-utils.js
import { db } from "./firebase-config"; // Make sure this points to your actual Firebase config file
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  Timestamp,
  startAfter,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  serverTimestamp,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { functions } from "./firebase-config"; // Adjust the import path as needed
import { httpsCallable } from "firebase/functions";
import axios from "axios"; // Make sure to install axios for HTTP requests

// This function will fetch tasks 10 at a time with lazy loading and infinite scrolling
export const getHistoryTasks = async (userID, lastVisible) => {
  let q;
  const eodYesterday = new Date();
  eodYesterday.setHours(0, 0, 0, 0); // Sets to the start of today (midnight)
  const eodYesterdayTimestamp = Timestamp.fromDate(
    new Date(eodYesterday.getTime() - 1)
  ); // Subtract 1 millisecond to get EOD yesterday

  if (lastVisible) {
    q = query(
      collection(db, "users", userID, "todos"),
      where("dueOn", "<", eodYesterdayTimestamp),
      orderBy("dueOn", "desc"),
      startAfter(lastVisible),
      limit(30)
    );
  } else {
    q = query(
      collection(db, "users", userID, "todos"),
      where("dueOn", "<=", eodYesterdayTimestamp),
      orderBy("dueOn", "desc"),
      limit(30)
    );
  }

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error getting history tasks: ", error);
    throw new Error(error);
  }
};

// This function will fetch tasks that have been bookmarked by the user
export const getBookmarkedTasks = async (userID) => {
  const q = query(
    collection(db, "users", userID, "todos"),
    where("bookmarked", "==", true),
    orderBy("dueOn", "desc"), // Assuming you want the most recently created first
    limit(50) // Adjust the limit as necessary
  );

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error getting bookmarked tasks: ", error);
    throw new Error(error);
  }
};

export const getTodos = async (query) => {
  try {
    const querySnapshot = await getDocs(query);
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Capture the last document snapshot for pagination
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    // Return both the documents and the last document snapshot
    return { data, lastVisible };
  } catch (error) {
    console.error("Error getting todos: ", error);
    throw new Error(error);
  }
};

/**
 * Adds a new todo to the Firestore database.
 * @param {string} userId The ID of the user to whom the task belongs.
 * @param {Object} task The task object to add.
 */
export async function addTodo(userId, task) {
  try {
    // Specify the collection path dynamically based on the user ID
    const docRef = await addDoc(collection(db, "users", userId, "todos"), task);

    console.log("Task added with ID: ", docRef.id);
    return true; // Optionally return the new document's ID
  } catch (error) {
    console.error("Error adding task: ", error);
    return false;
  }
}

/**
 * Updates a todo to the Firestore database.
 * @param {string} userId The ID of the user to whom the task belongs.
 * @param {Object} task The task object to add.
 */
export async function updateTodo(userId, todoId, todo) {
  try {
    const todoRef = doc(db, "users", userId, "todos", todoId);
    await updateDoc(todoRef, todo);
    console.log("Updated Successfully");
    console.log(todo);
    return true;
  } catch (error) {
    console.error("Error updating: ", error);
    return false;
  }
}

// This function will delete a task from the Firestore database
export const deleteTodo = async (userId, todoId) => {
  try {
    await deleteDoc(doc(db, "users", userId, "todos", todoId));
    console.log("Todo deleted successfully");
    return true;
  } catch (error) {
    console.error("Error deleting todo:", error);
    return false;
  }
};

// Update completed status of a todo item
export const updateTodoCompletedStatus = async (
  userId,
  todoId,
  newCompletedStatus
) => {
  const todoRef = doc(db, "users", userId, "todos", todoId);
  try {
    await updateDoc(todoRef, {
      completed: newCompletedStatus,
      completedAt: newCompletedStatus ? serverTimestamp() : null,
    });
    console.log("Todo completed status updated successfully");
    return true;
  } catch (error) {
    console.error("Error updating todo completed status:", error);
    return false;
  }
};

// Update due date of a todo item
export const updateTodoDueDate = async (userId, todoId, selectedDate) => {
  const todoRef = doc(db, "users", userId, "todos", todoId);
  try {
    await updateDoc(todoRef, { dueOn: selectedDate });
    console.log("Due date updated successfully");
    return true;
  } catch (error) {
    console.error("Error updating due date:", error);
    return false;
  }
};

// Update the title (and tags) of a todo item
export const updateTodoTitleAndTags = async (
  userId,
  todoId,
  newTitle,
  newTags,
  newDueOn
) => {
  const todoRef = doc(db, "users", userId, "todos", todoId);
  try {
    const updateData = {
      title: newTitle,
      tags: newTags,
    };

    // Conditionally add the dueOn field if newDueOn is not null
    if (newDueOn !== null) {
      updateData.dueOn = newDueOn;
    }

    await updateDoc(todoRef, updateData);
    console.log("Todo title and tags updated successfully");
    return true;
  } catch (error) {
    console.error("Error updating todo title and tags:", error);
    return false;
  }
};

// Update content of a todo item
export const updateTodoContent = async (userId, todoId, updatedContent) => {
  const todoRef = doc(db, "users", userId, "todos", todoId);
  try {
    await updateDoc(todoRef, { content: updatedContent });
    console.log("Todo content updated successfully");
    return true;
  } catch (error) {
    console.error("Error updating todo content:", error);
    return false;
  }
};

// Update estimated or actual time of a todo item
export const updateTodoTime = async (userId, todoId, selectedTime, type) => {
  const todoRef = doc(db, "users", userId, "todos", todoId);
  const fieldToUpdate = type === "estimate" ? "estimatedTime" : "actualTime";
  try {
    await updateDoc(todoRef, { [fieldToUpdate]: selectedTime });
    console.log(`Todo ${type} time updated successfully`);
    return true;
  } catch (error) {
    console.error(`Error updating ${type} time:`, error);
    return false;
  }
};

// Add the addBookmark function
export const addBookmark = async (userId, todoId, bookmarkStatus) => {
  try {
    const todoRef = doc(db, "users", userId, "todos", todoId); // Adjust the path according to your Firestore structure
    await updateDoc(todoRef, {
      bookmarked: bookmarkStatus,
    });
    return true;
  } catch (error) {
    console.error("Error updating bookmark status: ", error);
    return false;
  }
};

// Helper function to refresh the access token
async function refreshAccessToken(userId, refreshToken) {
  // const refreshFunction = httpsCallable(functions, "refreshAccessToken");
  // try {
  //   // Call the Firebase Function with the necessary parameters
  //   const result = await refreshFunction({ userId, refreshToken });
  //   const { accessToken, expiresAt } = result.data;
  //   return accessToken; // Return the new access token to the caller
  // } catch (error) {
  //   console.error("Failed to refresh access token:", error);
  //   throw new Error("Failed to refresh access token");
  // }

  const GRANT_TYPE = "refresh_token";

  const params = new URLSearchParams();
  params.append("client_id", process.env.REACT_APP_GOOGLE_CLIENT_ID);
  params.append("client_secret", process.env.REACT_APP_GOOGLE_CLIENT_SEC);
  params.append("refresh_token", refreshToken);
  params.append("grant_type", GRANT_TYPE);

  try {
    const response = await axios.post(
      "https://oauth2.googleapis.com/token",
      params
    );
    console.log("New access token:", response.data.access_token);
    // Here you handle the new access token (e.g., update the state, local storage, etc.)
    // Be cautious with storing tokens in local storage for security reasons
    saveTokenstoFirestore(userId, response.data);
    return response.data.access_token;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    // Handle error (e.g., user notification, logging, etc.)
    throw error;
  }
}

// Main function to get the access token, refreshing it if necessary
export async function getAccessToken(userId) {
    const userTokenRef = doc(db, "atokens", userId);
    const docSnap = await getDoc(userTokenRef);
    console.log("Retrieving at for events");
    if (docSnap.exists()) {
      const accessToken = docSnap.data().access_token;
      const refreshToken = docSnap.data().refresh_token;
      const expiresIn = docSnap.data().expires_in;

      // Check if the current access token is expired
      if (Date.now() > expiresIn) {
        // Refresh the token using the updated server-side logic
        return await refreshAccessToken(userId, refreshToken);
      } else {
        // Return the current access token if it's not expired
        return accessToken;
      }
    } else {
      console.log("No token document found for user:", userId);
      return null; // Adjust based on how you want to handle missing tokens
    }
}

/**
 * Fetch user settings from Firestore.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<Object>} - The user settings object.
 *
 * The fetchUserSettings function retrieves the user settings from Firestore based on the provided userId.
 * It returns an empty object or default settings if the specified document does not exist,
 * ensuring your application can handle users without existing settings.
 */
export const fetchUserSettings = async (userId) => {
  // const docRef = doc(db, "userSettings", userId);
  // const docSnap = await getDoc(docRef);
  // if (docSnap.exists()) {
  //   return docSnap.data(); // Return the settings if the document exists
  // } else {
  //   console.log("No such document!"); // Handle the case where the document does not exist
  //   return {}; // Return an empty object or default settings
  // }
};

/**
 * Save user settings to Firestore.
 * @param {string} userId - The ID of the user.
 * @param {Object} settings - The settings object to save.
 * @returns {Promise<void>}
 *
 * The saveUserSettings function updates the user settings in Firestore for the given userId. It uses { merge: true }
 * to merge the provided settings with any existing data, allowing for partial updates without overwriting unrelated fields.
 */
export const saveUserSettings = async (userId, settings) => {
  try {
    const docRef = doc(db, "userSettings", userId);
    await setDoc(docRef, settings, { merge: true }); // Use { merge: true } to not overwrite the entire document
    console.log("Document written with ID: ", userId);
    return true; // Indicate success
  } catch (error) {
    console.error("Error writing document: ", error);
    return false; // Indicate failure
  }
};

export const saveTokenstoFirestore = async (userId, tokens) => {
  try {
    const docRef = doc(db, "atokens", userId);
    const expirationTimestamp = Date.now() + tokens.expires_in * 1000;

    // Include the expirationTimestamp in the document
    const tokenData = {
      ...tokens,
      expires_in: expirationTimestamp, // Save this calculated value
    };

    await setDoc(docRef, tokenData, { merge: true }); // Use { merge: true } to not overwrite the entire document
    console.log("Tokens saved");
    return true; // Indicate success
  } catch (error) {
    console.error("Error writing document: ", error);
    return false; // Indicate failure
  }
};
