// HashtagFilterBar.js

import React from "react";
import "./HashtagFilterBar.css";
import { extractTags } from "./utils/TodoUtils";

const HashtagFilterBar = ({
  todos,
  selectedHashtags,
  toggleHashtag,
  onSelectAll,
  onClearSelection,
}) => {
  // Extract all unique hashtags from the todos
  const allHashtags = [
    ...new Set(todos.flatMap((todo) => extractTags(todo.title))),
  ].sort();

  return (
    <div className="hashtag-filter-bar">
      <span
        className={`select-clear-btn ${
          selectedHashtags.length > 0 ? "clear" : ""
        }`}
        onClick={selectedHashtags.length > 0 ? onClearSelection : onSelectAll}
      >
        {selectedHashtags.length > 0 ? "Clear" : "Select All"}
      </span>
      {allHashtags.map((hashtag) => (
        <span
          key={hashtag}
          className={`hashtag ${
            selectedHashtags.includes(hashtag) ? "selected" : ""
          }`}
          onClick={() => toggleHashtag(hashtag)}
        >
          #{hashtag}
        </span>
      ))}
    </div>
  );
};

export default HashtagFilterBar;
