// NavIcon.js
import React from "react";
import "./NavIcon.css"; // Import the CSS for styling

// The component takes a 'name' prop which can be used to determine the icon
function NavIcon({ name, title, onClick }) {
  // The 'icon' variable could be a class name or an image source depending on your icons
  const icon = `icon-${name}`;
  return (
    <div className={`nav-icon ${icon}`} title={title} onClick={onClick}>
      {/* Here you can use an img tag if you're using image icons or an i tag for font icons */}
      {/* Example for font-awesome icons: <i className={`fa fa-${name}`}></i> */}
      {/* Example for image icons: <img src={`/path/to/${name}.svg`} alt={name} /> */}
    </div>
  );
}

export default NavIcon;
