// TodoItemContent.js

// content is the initial content passed to the editor.
// onSaveContent is a function passed from the parent component to handle saving the content.
// setText and setIsContentChanged are used to manage the editor's state.

import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TodoItemContent.css";
// import { debounce } from "lodash"; // If not yet installed, use: npm install lodash

const TodoItemContent = ({ content, onSaveContent }) => {
  const [text, setText] = useState(content);
  const editorRef = useRef(null);

  // Debounced save function
  // const debouncedSave = useCallback(
  //   debounce((text) => {
  //     onSaveContent(text);
  //   }, 5000), // Adjust the time according to your needs
  //   [] // Dependencies array, it's empty since debounce creates a stable function
  // );

  // Update the text when the content prop changes
  useEffect(() => {
    setText(content);
  }, [content]);

  const handleTextChange = (content) => {
    setText(content);
  };

  // const handleTextChange = useCallback(
  //   (content) => {
  //     setText(content);
  //     debouncedSave(content);
  //   },
  //   [debouncedSave]
  // );

  // Handle click outside editor to save content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        // debouncedSave.flush(); // Immediately invoke any pending debounced calls
        if (text !== content) {
          onSaveContent(text); // Save the content
        }
      }
    };

    // Only add the event listener if the editor is mounted
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      // debouncedSave.cancel(); // On unmount, cancel any pending debounced calls
    };
  }, [text, content, onSaveContent]);

  return (
    <div ref={editorRef} className="todo-item-content-container">
      <ReactQuill value={text} onChange={handleTextChange} />
    </div>
  );
};

export default TodoItemContent;
