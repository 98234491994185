// utils/DateUtils.js

import { Timestamp } from "firebase/firestore";

export const getStartOfToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

export const getEndOfToday = () => {
  const endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  return endOfToday;
};

export const getEndOfWeek = () => {
  const endOfWeek = new Date();
  // Adjust the date to the end of the week (Sunday)
  endOfWeek.setDate(endOfWeek.getDate() + (7 - endOfWeek.getDay()));
  endOfWeek.setHours(23, 59, 59, 999); // Set to the very end of the day
  return endOfWeek;
};

export const getEodYesterdayTimestamp = () => {
  const eodYesterday = new Date();
  eodYesterday.setHours(0, 0, 0, 0); // Sets to the start of today (midnight)
  const eodYesterdayTimestamp = Timestamp.fromDate(
    new Date(eodYesterday.getTime() - 1)
  ); // Subtract 1 millisecond to get EOD yesterday
  return eodYesterdayTimestamp;
};

/**
 * Formats a Firebase Timestamp to a local date string.
 *
 * @param {firebase.firestore.Timestamp} timestamp - The timestamp from Firestore.
 * @param {string} [locale='en-US'] - The locale you want to use for formatting the date.
 * @param {object} [options={}] - The options to control the format of the date string.
 * @returns {string} - The formatted date string.
 *
 * if you want to show just the date in a shorter form, you could call formatTimestamp(createdAt, 'en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).
 *
 * To use:
 * import { formatTimestamp } from './utils';
 *
 * ... inside your component where you want to display the date
 *
 * Assuming 'createdAt' is the Firestore Timestamp you want to display
 * const formattedDate = formatTimestamp(createdAt);
 *
 * In your JSX
 * return (
 *   <span>{formattedDate}</span>
 * );
 * #todo make this local dynamic based on user's timezone
 */
export const formatTimestamp = (timestamp, locale = "en-US", options = {}) => {
  // Provide default formatting options if none are provided
  const defaultOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    ...options, // This allows overriding and extending the default options
  };

  // Convert the Timestamp to a JavaScript Date object
  const date = timestamp.toDate();

  // Use Intl.DateTimeFormat to format the date
  const formatter = new Intl.DateTimeFormat(locale, defaultOptions);

  // Return the formatted date string
  return formatter.format(date);
};

/**
 * Converts time from decimal to hour:minute format.
 * @param {number} decimalTime - The time in decimal format (e.g., 1.5 for one and a half hour).
 * @returns {string} - The time in HH:mm format.
 */
export function formatTime(decimalTime) {
  if (!decimalTime) {
    return "00:00";
  }
  const hours = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime - hours) * 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}
