// HistoryView.js

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  HISTORYVIEW_TABS,
  PAGINATION_ITEMS_AT_A_TIME,
} from "./utils/Constants";
import { useUser } from "./UserContext";
import "./HistoryView.css";
import HistoryViewTodoItem from "./HistoryViewTodoItem";
import { getTodos } from "./firebase-utils";
import {
  query,
  collection,
  where,
  orderBy,
  startAfter,
  limit,
} from "firebase/firestore";
import { db } from "./firebase-config";
import { getEodYesterdayTimestamp } from "./utils/DateUtils";

function HistoryView() {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("historyViewActiveTab") || HISTORYVIEW_TABS.HISTORY
  ); // This state will track which tab is active
  const [tasks, setTasks] = useState([]); // This state will hold our tasks
  const [loading, setLoading] = useState(false); // This state will indicate if we are currently loading data
  const { currentUser } = useUser();
  const [lastVisible, setLastVisible] = useState(null); // For Firestore pagination
  const [hasMore, setHasMore] = useState(false); // To track if there's more data to load
  const loaderRef = useRef(null); // Ref for the loader div

  const fetchTasks = async (createdQuery) => {
    if (!hasMore) setLoading(true); // Use setLoading only for the initial load

    try {
      const { data, lastVisible } = await getTodos(createdQuery);
      if (data.length > 0) {
        setTasks((prevTasks) => [...prevTasks, ...data]);
        setLastVisible(lastVisible);
        setHasMore(data.length >= PAGINATION_ITEMS_AT_A_TIME);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  // useCallback ensures that handleObserver has a stable reference
  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        // Create a new query starting after the last visible document

        let nextQuery;
        if (activeTab === HISTORYVIEW_TABS.HISTORY) {
          const eodYesterdayTimestamp = getEodYesterdayTimestamp();
          nextQuery = query(
            collection(db, "users", currentUser.uid, "todos"),
            where("dueOn", "<=", eodYesterdayTimestamp),
            orderBy("dueOn", "desc"),
            startAfter(lastVisible),
            limit(PAGINATION_ITEMS_AT_A_TIME)
          );
        } else if (activeTab === HISTORYVIEW_TABS.BOOKMARKS) {
          // Adjust this query based on your needs for "Bookmarks"
          nextQuery = query(
            collection(db, "users", currentUser.uid, "todos"),
            where("bookmarked", "==", true),
            orderBy("dueOn", "desc"),
            startAfter(lastVisible),
            limit(PAGINATION_ITEMS_AT_A_TIME)
          );
        }

        fetchTasks(nextQuery);
      }
    },
    [hasMore, lastVisible, currentUser, activeTab]
  );

  const updateTodoInLocalState = (id, updatedFields) => {
    setTasks((prevTodos) =>
      prevTodos.map((todo) =>
        todo.id === id ? { ...todo, ...updatedFields } : todo
      )
    );
  };

  useEffect(() => {
    localStorage.setItem("historyViewActiveTab", activeTab);
    let createdQuery;
    if (activeTab === HISTORYVIEW_TABS.HISTORY) {
      const eodYesterdayTimestamp = getEodYesterdayTimestamp();
      createdQuery = query(
        collection(db, "users", currentUser.uid, "todos"),
        where("dueOn", "<=", eodYesterdayTimestamp),
        orderBy("dueOn", "desc"),
        limit(PAGINATION_ITEMS_AT_A_TIME)
      );
    } else if (activeTab === HISTORYVIEW_TABS.BOOKMARKS) {
      // Adjust this query based on your needs for "Bookmarks"
      createdQuery = query(
        collection(db, "users", currentUser.uid, "todos"),
        where("bookmarked", "==", true),
        orderBy("dueOn", "desc"), // Assuming you want the most recently created first
        limit(PAGINATION_ITEMS_AT_A_TIME) // Adjust the limit as necessary
      );
    }
    setTasks([]); // Clear existing tasks on tab switch
    fetchTasks(createdQuery);
  }, [activeTab, currentUser]);

  useEffect(() => {
    const option = {
      root: null, // Use the viewport as root
      rootMargin: "20px",
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loaderRef.current) observer.observe(loaderRef.current);

    return () => observer.disconnect(); // Cleanup the observer on component unmount
  }, [handleObserver]);

  const renderTasks = () => {
    // For the bookmarks tab or other cases where tasks aren't grouped by date,
    // you can return a simple list or implement a different component/logic
    if (activeTab === HISTORYVIEW_TABS.BOOKMARKS) {
      return (
        <HistoryViewTodoItem
          tasks={tasks}
          updateTodoInLocalState={updateTodoInLocalState}
        />
      );
    }

    // Use HistoryViewTodoItem or similar for "Last 30 Days" to leverage date grouping
    return (
      <HistoryViewTodoItem
        tasks={tasks}
        updateTodoInLocalState={updateTodoInLocalState}
      />
    );
  };

  return (
    <div className="history-container d-flex w-100 justify-content-center">
      <div className="history-view d-flex">
        <div className="tabs-wrapper w-100 mt-3">
          <div className="tabs w-100 d-flex">
            <button
              className={`tab ${
                activeTab === HISTORYVIEW_TABS.HISTORY ? "active" : ""
              } button-tab`}
              onClick={() => setActiveTab(HISTORYVIEW_TABS.HISTORY)}
            >
              {HISTORYVIEW_TABS.HISTORY}
            </button>
            <button
              className={`tab ${
                activeTab === HISTORYVIEW_TABS.BOOKMARKS ? "active" : ""
              } button-tab`}
              onClick={() => setActiveTab(HISTORYVIEW_TABS.BOOKMARKS)}
            >
              {HISTORYVIEW_TABS.BOOKMARKS}
            </button>
          </div>
          <div className="task-list w-100">
            {loading ? (
              <div className="loading-container d-flex">Loading...</div>
            ) : (
              renderTasks()
            )}
          </div>
          {hasMore && (
            <div ref={loaderRef} className="loading-more">
              Loading more...
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoryView;
