/**
 * Generates the OAuth2 redirect URI dynamically based on the current hostname.
 * Adjusts the path if necessary, depending on your application's routing structure.
 * @return {string} The dynamically generated redirect URI.
 */
export const getRedirectUri = () => {
  // Define the path where your application expects the OAuth2 callback.
  // Adjust this path as necessary to match your application's routing.
  const redirectPath = "/authorization";

  // Construct the redirect URI using the current protocol, hostname, and the specified path.
  const redirectUri = `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? ":" + window.location.port : ""}${redirectPath}`;

  return redirectUri;
};
