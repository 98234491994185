// CalendarViewIntegrationModal.js
import React from "react";
import "./CalendarViewIntegrationModal.css";

const CalendarViewIntegrationModal = ({ children, onClose }) => {
  return (
    <div className="cimodal-overlay" onClick={onClose}>
      <div className="cimodal-content" onClick={(e) => e.stopPropagation()}>
        {children}
        <button className="cimodal-close-btn" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default CalendarViewIntegrationModal;
