// LoginScreen.js
import React from "react";
import SignIn from "./SignIn";
import "./LoginScreen.css";

const LoginScreen = () => {
  return (
    <div className="login-screen d-flex">
      <div className="login-image">
        <img src="Login screen.webp" alt="Momentum" />
      </div>
      <div className="login-wrapper">
        <div className="login-head">
          <div className="heading-text">
            <h1>Welcome to Momentum</h1>
            <p>Please sign in to continue</p>
          </div>
          <SignIn />
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
