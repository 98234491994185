// NavigationSidebar.js
import React, { useEffect } from "react";
import NavIcon from "./NavIcon";
import "./NavigationSidebar.css"; // Your CSS here
import { auth } from "./firebase-config";
import { useUser } from "./UserContext"; // Import the useUser hook
import { SIDEBAR_ICON_NAMES } from "./utils/Constants"; // Import the icon names
import { useNavigate } from "react-router-dom";

function NavigationSidebar({ onIconClick }) {
  // Use UserContext to access user details
  const { currentUser, setCurrentUser } = useUser();
  const navigate = useNavigate();

  //write comment on what this useEffect does

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user); // Correctly use setCurrentUser from context to update user state
    });
    return () => unsubscribe(); // Cleanup the subscription on component unmount
  }, [setCurrentUser]); // Correct dependency

  const handleIconClick = (iconName) => {
    console.log(`${iconName} clicked`);
    if (iconName === SIDEBAR_ICON_NAMES.LOGOUT) {
      handleLogout();
    } else {
      // Use the navigate function here to update the URL
      switch (iconName) {
        case SIDEBAR_ICON_NAMES.CALENDAR_TODO:
          navigate("/dashboard");
          break;
        case SIDEBAR_ICON_NAMES.HISTORY:
          navigate("/history");
          break;
        case SIDEBAR_ICON_NAMES.PLANNING:
          navigate("/history"); // Add the corresponding route in your App.js
          break;
        case SIDEBAR_ICON_NAMES.ANALYTICS:
          navigate("/history"); // Add the corresponding route in your App.js
          break;
        case SIDEBAR_ICON_NAMES.ACCOUNT:
          navigate("/account");
          break;
        default:
          break;
      }
    }
  };

  function handleLogout() {
    auth
      .signOut()
      .then(() => {
        console.log("User logged out");
        navigate("/login"); // Navigate to the login screen after logout
      })
      .catch((error) => {
        console.error("Logout error", error);
      });
  }

  return (
    <div className="navigation-sidebar">
      {Object.values(SIDEBAR_ICON_NAMES).map((icon) => (
        <NavIcon
          key={icon.name}
          name={icon.name}
          title={icon.title}
          onClick={() => handleIconClick(icon)}
        />
      ))}
    </div>
  );
}

export default NavigationSidebar;
